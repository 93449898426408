<template>
  <div class="admin-close-page custom-container">
    <div class="header-wrapper">
      <h4 class="title">
        Geschlossene Tage
      </h4>
      <b-input-group>
        <b-form-input
          id="filterInput"
          v-model="filter"
          type="search"
          placeholder="Tippe um zu filtrieren"
          debounce="200"
        />
        <b-input-group-append>
          <b-button
            :disabled="!filter"
            @click="filter = ''"
          >
            Klar
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
    <b-tabs
      v-model="tabIndex"
      content-class="mt-3"
    >
      <b-tab
        v-for="(tabName, index) in tabNames"
        :key="`closeDateTab${index}`"
        :title="tabName"
      >
        <div class="table-box">
          <b-table
            :items="tableItems"
            :fields="tableFields"
            :filter="filter"
            :busy="isLoadingCloseDate"
            bordered
            responsive
            striped
            hover
            show-empty
          >
            <template v-slot:head(function)>
              <b-btn
                variant="tab-orange"
                size="sm"
                @click="onOpenCreateClose"
              >
                Neu
              </b-btn>
            </template>
            <template #table-busy>
              <div class="text-center text-tab-orange my-4">
                <b-spinner class="align-middle" />
              </div>
            </template>
            <template v-slot:empty>
              <div class="empty-slot">
                <h4>KEINE EREIGNISSE</h4>
              </div>
            </template>
            <template v-slot:cell(startDate)="{ item }">
              {{ item.startDatetime.split(' ')[0] | germanDate }}
            </template>
            <template v-slot:cell(endDate)="{ item }">
              {{ item.endDatetime.split(' ')[0] | germanDate }}
            </template>
            <template v-slot:cell(startTime)="{ item }">
              {{ item.startDatetime.split(' ')[1] }}
            </template>
            <template v-slot:cell(endTime)="{ item }">
              {{ item.endDatetime.split(' ')[1] }}
            </template>
            <template v-slot:cell(function)="{ item }">
              <div class="function-td">
                <button @click="onDeleteCloseDate(item.identification)">
                  <icon-trash />
                </button>
              </div>
            </template>
          </b-table>
        </div>
      </b-tab>
    </b-tabs>

    <base-modal
      id="admin-add-close-modal"
      title="Ganzen Tag sperren"
      custom-class="admin-create-event-modal"
      size="lg"
      admin
      no-close-on-backdrop
    >
      <b-row class="booking-form">
        <b-col
          v-for="(inputItem, index) in addCloseInputFormItems"
          :key="`${inputItem.key}${index}`"
          :md="inputItem.col"
        >
          <b-form-group
            v-if="inputItem.type === 'date'"
            :label="inputItem.label"
            :label-for="`input-${inputItem.key}`"
            label-cols-sm="3"
            :state="isErrorAddCloseForm[inputItem.key]"
            :invalid-feedback="errorMsgAddCloseForm[inputItem.key]"
            :description="inputItem.description"
          >
            <b-form-datepicker
              v-if="inputItem.key === 'startDate'"
              :id="`input-${inputItem.key}`"
              v-model="addCloseInputForm[inputItem.key].value"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              locale="de"
              @input="isErrorAddCloseForm[inputItem.key] = null"
            />
            <b-form-datepicker
              v-else-if="inputItem.key === 'endDate'"
              :id="`input-${inputItem.key}`"
              v-model="addCloseInputForm[inputItem.key].value"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              :min="addCloseInputForm['startDate'].value"
              locale="de"
              @input="isErrorAddCloseForm[inputItem.key] = null"
            />
          </b-form-group>
          <b-form-group
            v-else-if="inputItem.key === 'timeFrom'"
            :label="inputItem.label"
            :label-for="`input-${inputItem.key}`"
            label-cols-sm="3"
            :state="isErrorAddCloseForm[inputItem.key]"
            :invalid-feedback="errorMsgAddCloseForm[inputItem.key]"
          >
            <vue-timepicker
              :id="`input-${inputItem.key}`"
              v-model="addCloseInputForm[inputItem.key].value"
              format="HH:mm:ss"
              @input="isErrorAddCloseForm[inputItem.key] = null"
            />
          </b-form-group>
          <b-form-group
            v-else-if="inputItem.key === 'timeTo'"
            :label="inputItem.label"
            :label-for="`input-${inputItem.key}`"
            label-cols-sm="3"
            :state="isErrorAddCloseForm[inputItem.key]"
            :invalid-feedback="errorMsgAddCloseForm[inputItem.key]"
          >
            <vue-timepicker
              :id="`input-${inputItem.key}`"
              v-model="addCloseInputForm[inputItem.key].value"
              format="HH:mm:ss"
              @input="isErrorAddCloseForm[inputItem.key] = null"
            />
          </b-form-group>
          <b-form-group
            v-else-if="inputItem.type === 'select'"
            :label="inputItem.label"
            :label-for="`input-${inputItem.key}`"
            label-cols-sm="3"
            :state="isErrorAddCloseForm[inputItem.key]"
            :invalid-feedback="errorMsgAddCloseForm[inputItem.key]"
          >
            <b-form-select
              :id="`input-${inputItem.key}`"
              v-model="addCloseInputForm[inputItem.key].value"
              :options="addCloseInputForm[inputItem.key].options"
              @input="onInputSelectCloseDate(inputItem.key)"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >
                  -- Betrag auswählen --
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            v-else-if="inputItem.type === 'text'"
            :label="inputItem.label"
            :label-for="`input-${inputItem.key}`"
            label-cols-sm="3"
            :state="isErrorAddCloseForm[inputItem.key]"
            :invalid-feedback="errorMsgAddCloseForm[inputItem.key]"
          >
            <base-input
              v-model="addCloseInputForm[inputItem.key].value"
              :type="inputItem.type"
              :required="inputItem.required"
              :input-id="`addCloseInputFormInput${inputItem.key}`"
              @input="isErrorAddCloseForm[inputItem.key] = null"
            />
          </b-form-group>
          <b-form-group
            v-else-if="inputItem.type === 'textarea'"
            :label="inputItem.label"
            :label-for="`input-${inputItem.key}`"
            :state="isErrorAddCloseForm[inputItem.key]"
            :invalid-feedback="errorMsgAddCloseForm[inputItem.key]"
          >
            <div class="base-input textarea">
              <textarea
                :id="`input-${inputItem.key}`"
                v-model="addCloseInputForm[inputItem.key].value"
                @input="isErrorAddCloseForm[inputItem.key] = null"
              />
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-btn
        variant="tab-orange"
        class="mt-1"
        @click="onSubmitAddClose"
      >
        speichern
      </b-btn>
    </base-modal>
  </div>
</template>

<style lang="scss">
@import "@/styles/pages/admin-close.scss";
</style>


<script>
require('@/plugins/datejs/date-de-DE');
import IconTrash from '@/components/icons/IconTrash';

import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import { mapState, mapGetters } from 'vuex';
import { AdminInformation } from '@/services';
import { loader } from '@/plugins/loader';
import { germanDate } from '@/helpers/filters/germanDate';

import {
  initAddCloseInputForm,
  initAddCloseErrorForm,
  initAddCloseErrorMsg,
  tableFields,
} from './config';

export default {
  components: {
    IconTrash,
    VueTimepicker,
  },
  filters: { germanDate },
  data() {
    return {
      filter: '',
      today: '',
      addCloseInputForm: initAddCloseInputForm,
      isErrorAddCloseForm: initAddCloseErrorForm,
      errorMsgAddCloseForm: initAddCloseErrorMsg,
      isLoadingCloseDate: false,
      tableItems: [],
      tabIndex: 0,
      tabNames: ['Adventure Golf', 'Golf Room', 'SkillCourt'],
    };
  },
  computed: {
    ...mapState('admin', ['events', 'notices']),
    ...mapState('base', ['activities']),
    ...mapGetters('base', ['getArenaActivitiesName']),
    tableFields() {
      return tableFields;
    },
    addCloseInputFormItems() {
      return Object.values(this.addCloseInputForm);
    },
    isBookingFormError() {
      return Object.values(this.isErrorBookingForm).some((val) => val === false);
    },
    isAddCloseFormError() {
      return Object.values(this.isErrorAddCloseForm).some((val) => val === false);
    },
  },
  watch: {
    tabIndex() {
      this.loadCloseDate();
    },
  },
  created() {
    this.today = Date.today();
    this.loadCloseDate();
  },
  methods: {
    async loadCloseDate() {
      this.isLoadingCloseDate = true;
      try {
        await this.$store.dispatch('admin/showNotices', {
          activityType: this.tabNames[this.tabIndex],
        });
        this.tableItems = this.notices;
        this.isLoadingCloseDate = false;
      } catch {
        this.tableItems = [];
        this.isLoadingCloseDate = false;
      }
    },
    onOpenCreateClose() {
      this.resetFormCloseDate();
      this.addCloseInputForm.startDate.value = Date.today().toString('yyyy-MM-dd');
      this.addCloseInputForm.activityType.value = this.tabNames[this.tabIndex];
      if (this.tabNames[this.tabIndex] === 'Adventure Golf') {
        this.addCloseInputForm.information.value = 'Leider müssen wir für heute die Anlage zwecks den Witterungsbedingungen schließen.';
      }
      this.$bvModal.show('admin-add-close-modal');
    },
    async onDeleteCloseDate(id) {
      this.$bvModal.msgBoxConfirm('Are you sure to delete? This action cannot be undone.', {
        title: 'Delete event',
        okVariant: 'tab-orange',
        headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
        footerClass: 'p-2 border-top-0',
        centered: true,
      })
        .then(async (val) => {
          if (!val) {
            return;
          }
          try {
            loader.start();
            await this.$store.dispatch('admin/removeCloseDate', {
              identification: id,
            });
            this.tableItems = this.notices;
            loader.stop();
            this.$bvModal.msgBoxOk('Selected close date is deleted', {
              title: 'Delete successful',
              okVariant: 'tab-orange',
              headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
              footerClass: 'p-2 border-top-0',
              centered: true,
            });
          } catch (err) {
            loader.stop();
            this.$bvModal.msgBoxOk(err, {
              title: 'Delete Error',
              okVariant: 'tab-orange',
              headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
              footerClass: 'p-2 border-top-0',
              centered: true,
            });
          }
        });
    },
    validateAddCloseInput() {
      Object.values(this.addCloseInputForm).forEach((item) => {
        if (item.required && item.value === '') {
          this.isErrorAddCloseForm = {
            ...this.isErrorAddCloseForm,
            [item.key]: false,
          };
          this.errorMsgAddCloseForm = {
            ...this.errorMsgAddCloseForm,
            [item.key]: 'Dieses Feld wird benötigt',
          };
        } else if (item.type === 'time' && !/^([0-9][0-9]:[0-9][0-9]:[0-9][0-9])$/.test(item.value)) {
          this.isErrorAddCloseForm = {
            ...this.isErrorAddCloseForm,
            [item.key]: false,
          };
          this.errorMsgAddCloseForm = {
            ...this.errorMsgAddCloseForm,
            [item.key]: 'Bitte geben Sie die Endzeit ein',
          };
        } else if (item.value !== '' && item.type === 'time') {
          const today = Date.today().toString('dd/MM/yyyy');
          if (this.addCloseInputForm.startDate.value === this.addCloseInputForm.endDate.value &&
                        Date.parse(`${today} ${this.addCloseInputForm.timeTo.value}`) < Date.parse(`${today} ${this.addCloseInputForm.timeFrom.value}`)
          ) {
            this.isErrorAddCloseForm = {
              ...this.isErrorAddCloseForm,
              ['timeFrom']: false,
            };
            this.errorMsgAddCloseForm = {
              ...this.errorMsgAddCloseForm,
              ['timeFrom']: 'Von should less than Bis',
            };
          }
        } else {
          this.isErrorAddCloseForm = {
            ...this.isErrorAddCloseForm,
            [item.key]: null,
          };
          this.errorMsgAddCloseForm = {
            ...this.errorMsgAddCloseForm,
            [item.key]: '',
          };
        }
      });
    },
    async onSubmitAddClose() {
      this.validateAddCloseInput();
      if (this.isAddCloseFormError) {
        return;
      }
      loader.start();
      let payload = {};
      Object.keys(this.addCloseInputForm).forEach((key) => {
        payload = {
          ...payload,
          [key]: this.addCloseInputForm[key].value,
        };
      });
      payload = {
        ...payload,
        startDate: `${payload.startDate} ${payload.timeFrom}`,
        endDate: `${payload.endDate} ${payload.timeTo}`,
      };

      const foundSameTimeFrameCloseSlot = this.notices.find((notice) => {
        const noticeStartDatetimeStringFormat = notice.startDatetime.replace(' ', 'T');
        const noticeStartDatetimeParse = Date.parse(noticeStartDatetimeStringFormat);
        const noticeEndDatetimeStringFormat = notice.endDatetime.replace(' ', 'T');
        const noticeEndDatetimeParse = Date.parse(noticeEndDatetimeStringFormat);

        const payloadStartDatetimeStringFormat = payload.startDate.replace(' ', 'T');
        const payloadStartDatetimeParse = Date.parse(payloadStartDatetimeStringFormat);
        const payloadEndDatetimeStringFormat = payload.endDate.replace(' ', 'T');
        const payloadEndDatetimeParse = Date.parse(payloadEndDatetimeStringFormat);
        return (noticeStartDatetimeParse.between(payloadStartDatetimeParse, payloadEndDatetimeParse)
                    || noticeEndDatetimeParse.between(payloadStartDatetimeParse, payloadEndDatetimeParse)
        ) && !Date.equals(payloadStartDatetimeParse, noticeEndDatetimeParse)
                && !Date.equals(payloadEndDatetimeParse, noticeStartDatetimeParse)
                && payload.statusType === notice.status;
      });

      if (!foundSameTimeFrameCloseSlot) {
        try {
          const response = await AdminInformation.makeNotice(payload);
          loader.stop();
          if (response.status === 200 && response.data.status === false) {
            this.$bvModal.msgBoxOk(response.data.message, {
              title: 'Add close date Error',
              okVariant: 'tab-orange',
              headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
              footerClass: 'p-2 border-top-0',
              centered: true,
            });
            return;
          }
          this.$bvModal.msgBoxOk('Add close date successful', {
            title: 'Add close date',
            okVariant: 'tab-orange',
            headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
            footerClass: 'p-2 border-top-0',
            centered: true,
          }).then(() => {
            this.loadCloseDate();
            this.$bvModal.hide('admin-add-close-modal');
            this.resetFormCloseDate();
          });
        } catch (err) {
          loader.stop();
          if (err.response && err.response.status === 401) {
            return;
          }

          this.$bvModal.msgBoxOk(err.response && err.response.data
            ? err.response.data.information
            : 'Something error', {
            title: 'Add close date Error',
            okVariant: 'tab-orange',
            headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
            footerClass: 'p-2 border-top-0',
            centered: true,
          });
        }
        return;
      }

      loader.stop();
      const h = this.$createElement;
      const messageVNode = h('div', [
        h('p', { class: ['font-weight-bold', 'mb-2'] }, ['Startdatum']),
        h('p', { class: ['mb-2'] }, [foundSameTimeFrameCloseSlot.startDatetime]),
        h('p', { class: ['font-weight-bold', 'mb-2'] }, ['Enddatum']),
        h('p', { class: ['mb-2'] }, [foundSameTimeFrameCloseSlot.endDatetime]),
        h('p', { class: ['font-weight-bold', 'mb-2'] }, ['Status']),
        h('p', { class: ['mb-2'] }, [foundSameTimeFrameCloseSlot.status]),
        h('p', { class: ['font-weight-bold', 'mb-2'] }, ['Information']),
        h('p', { class: ['mb-2'] }, [foundSameTimeFrameCloseSlot.information]),
      ]);

      this.$bvModal.msgBoxConfirm(messageVNode, {
        title: 'Your selected date time is under another blocked time frame. Do you still want to continue?',
        okVariant: 'tab-orange',
        headerClass: 'p-2 py-3 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true,
      })
        .then(async (val) => {
          if (!val) {
            return;
          }

          loader.start();
          try {
            const response = await AdminInformation.makeNotice(payload);
            loader.stop();
            if (response.status === 200 && response.data.status === false) {
              this.$bvModal.msgBoxOk(response.data.message, {
                title: 'Add close date Error',
                okVariant: 'tab-orange',
                headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
                footerClass: 'p-2 border-top-0',
                centered: true,
              });
              return;
            }
            this.$bvModal.msgBoxOk('Add close date successful', {
              title: 'Add close date',
              okVariant: 'tab-orange',
              headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
              footerClass: 'p-2 border-top-0',
              centered: true,
            }).then(() => {
              this.loadCloseDate();
              this.$bvModal.hide('admin-add-close-modal');
              this.resetFormCloseDate();
            });
          } catch (err) {
            loader.stop();
            if (err.response && err.response.status === 401) {
              return;
            }

            this.$bvModal.msgBoxOk(err.response && err.response.data
              ? err.response.data.information
              : 'Something error', {
              title: 'Add close date Error',
              okVariant: 'tab-orange',
              headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
              footerClass: 'p-2 border-top-0',
              centered: true,
            });
          }
        });

    },
    resetFormCloseDate() {
      Object.values(initAddCloseInputForm).forEach((item) => {
        this.addCloseInputForm = {
          ...this.addCloseInputForm,
          [item.key]: {
            ...item,
          },
        };
      });
      Object.keys(initAddCloseErrorForm).forEach((key) => {
        this.isErrorAddCloseForm = {
          ...this.isErrorAddCloseForm,
          [key]: initAddCloseErrorForm[key],
        };
      });
    },
    onInputSelectCloseDate(key) {
      this.isErrorAddCloseForm[key] = null;

      if (key !== 'activityType') {
        return;
      }

      if (this.addCloseInputForm[key].value === 'Adventure Golf') {
        this.addCloseInputForm['information'].value = 'Leider müssen wir für heute die Anlage zwecks den Witterungsbedingungen schließen.';
      } else if (this.addCloseInputForm['information'].value !== '') {
        this.addCloseInputForm['information'].value = '';
      }
    },
  },
};
</script>