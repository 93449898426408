export const initAddCloseInputForm = {
  startDate: {
    value: '',
    col: '6',
    type: 'date',
    required: true,
    label: 'Start:',
    key: 'startDate',
    menu: 'dateMenu',
    description: 'Wählen Sie für einen Tag dasselbe Start- und Enddatum aus',
  },
  endDate: {
    value: '',
    col: '6',
    type: 'date',
    required: true,
    label: 'End:',
    key: 'endDate',
    menu: 'dateMenu',
    description: 'Wählen Sie für einen Tag dasselbe Start- und Enddatum aus',
  },
  timeFrom: {
    value: '00:00:00',
    col: '6',
    type: 'time',
    required: true,
    label: 'Von:',
    key: 'timeFrom',
    menu: 'dateMenu',
    description: 'Wählen Sie für einen Tag dasselbe Start- und Enddatum aus',
  },
  timeTo: {
    value: '23:59:59',
    col: '6',
    type: 'time',
    required: true,
    label: 'Bis:',
    key: 'timeTo',
    menu: 'dateMenu',
    description: 'Wählen Sie für einen Tag dasselbe Start- und Enddatum aus',
  },
  activityType: {
    value: '',
    col: '6',
    type: 'select',
    required: true,
    label: 'Raum:',
    key: 'activityType',
    options: ['Golf Room', 'SkillCourt', 'Adventure Golf'],
  },
  statusType: {
    value: 'Closed',
    col: '6',
    type: 'select',
    required: true,
    label: 'Status:',
    key: 'statusType',
    options: ['Closed', 'Holiday'],
  },
  information: {
    value: '',
    col: '12',
    type: 'textarea',
    required: false,
    label: 'Ihre Nachricht',
    key: 'information',
  },
};

export const initAddCloseErrorForm = {
  day: null,
  type: null,
  information: null,
};

export const initAddCloseErrorMsg = {
  day: '',
  type: '',
  information: '',
};

export const tableFields = [
  { key: 'startDate', label: 'Startdatum', class: 'text-center' },
  { key: 'endDate', label: 'Enddatum', class: 'text-center' },
  { key: 'startTime', label: 'Von', class: 'text-center' },
  { key: 'endTime', label: 'Bis', class: 'text-center' },
  // { key: 'type', label: 'Type', class: 'text-center' },
  { key: 'status', label: 'Status', class: 'text-center' },
  { key: 'information', label: 'Information' },
  { key: 'function', label: 'function', class: 'text-center function' },
];
